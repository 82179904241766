
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Skeleton extends Vue {
    height = '0px';

    mounted() {
        const el = this.$refs.skeleton as HTMLElement;
        const elY = el.getBoundingClientRect().y;
        const wHeight = window.innerHeight;
        this.height = `${wHeight - elY}px`;
    }
}
