
import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomDropdown from '@/modules/common/components/ui-kit/custom-dropdown.vue';

@Component({
    components: {
        CustomDropdown,
    },
})
export default class ExcelBtn extends Vue {
    // Temporary, for page where there are no download popups yet
    @Prop({
        type: Function,
        required: false,
    })
    downloadAction!: () => void;

    public get isPopupOpened() {
        return this.$route.name!.includes('.download-excel')
            || this.$route.name!.includes('.scheduled-report');
    }

    public get items() {
        if (this.isPopupOpened) return [];

        const isScheduledRouteDefined = this.$router
            .resolve({
                name: `${this.$route.name!}.scheduled-reports`,
            }).href !== '/';

        const isDownloadExcelDefined = this.$router
            .resolve({
                name: `${this.$route.name!}.download-excel`,
            }).href !== '/';

        return [
            {
                link: !this.downloadAction ? 'download-excel' : '',
                text: this.$tc('download'),
                disable: !isDownloadExcelDefined && !this.downloadAction,
                action: this.downloadAction,
            },
            {
                link: 'scheduled-reports',
                text: this.$tc('schedule'),
                disable: !isScheduledRouteDefined,
            },
        ];
    }
}
