import { Component, Mixins } from 'vue-property-decorator';
import { inject } from '@/inversify';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';

import BaseOtelMixin from './base-otel.mixin';
import { LOGTYPE } from '../../constants';

/**
 * Base mixin for cluster pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class ClusterOtelLogs extends Mixins(BaseOtelMixin) {
    @inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;
    @inject(ClusterServiceS) protected clusterService!: ClusterService;

    static readonly spanPrefix = LOGTYPE.LOADING;

    beforeMount() {
        this.startSpan(ClusterOtelLogs.spanPrefix);
    }

    mounted() {
        this.addEvent('mounted', ClusterOtelLogs.spanPrefix);

        // As we use only one loading model for all cluster pages, need this gap for loading model to update the state
        setTimeout(() => {
            this.clusterService.storeState.loading.whenLoadingFinished()
                .then(_ => {
                    this.addEvent('gotRespnose', ClusterOtelLogs.spanPrefix);
                    this.endSpan(ClusterOtelLogs.spanPrefix, { sendLogs: true, payload: this.payload });
                });
        }, 1);
    }

    beforeDestroy() {
        this.endSpan(ClusterOtelLogs.spanPrefix);
    }

    get payload() {
        return {};
    }
}
