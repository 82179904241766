import { Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import OpenTelemetryService, { OpenTelemetryServiceS } from '../../open-telemetry.service';
import { BaseLogsPublicInterface } from '../../types';
import { LOGTYPE } from '../../constants';

/**
 * Mixin to track user's staing duration on a page
 */
@Component
export default class BaseOtelMixin extends Vue implements BaseLogsPublicInterface {
    @inject(OpenTelemetryServiceS) protected otelService!: OpenTelemetryService;
    private get name() {
        return this.$route.name!;
    }

    startSpan(prefix?: LOGTYPE) {
        if (!this.name) {
            throw new Error('Span have to be inited.');
        }

        this.otelService.startSpan({ name: this.name, prefix });
        this.addEvent('start', prefix);
    }

    endSpan(prefix?: LOGTYPE, options?: { sendLogs?: boolean, payload?: Record<string, any> }) {
        this.addEvent('finish', prefix);
        this.otelService.endSpan({ name: this.name, prefix }, options);
    }

    addEvent(eventName: string, prefix?: LOGTYPE) {
        this.otelService.addEvent({ name: this.name, prefix }, eventName);
    }
}
