
import { Component, Prop, Vue } from 'vue-property-decorator';
import BigNumberFilter from '@/modules/common/filters/big-number.filter';

@Component({
    filters: { BigNumberFilter },
})
export default class Range extends Vue {
    @Prop({
        type: Number,
    })
    private current!: number;

    @Prop({
        type: Number,
    })
    private lowest!: number;

    @Prop({
        type: Number,
    })
    private highest!: number;

    @Prop({
        type: Number,
    })
    private minScale!: number;

    @Prop({
        type: Number,
    })
    private maxScale!: number;

    @Prop({
        type: Boolean,
        default: false,
    })
    private chain?: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private skeleton?: boolean;

    get leftOffset() {
        return (this.lowest - this.minScale) / this.rangeLength * 100;
    }

    get rightOffset() {
        return (this.maxScale - this.highest) / this.rangeLength * 99;
    }

    get currentPos() {
        return (this.current - this.minScale) / this.rangeLength * 100;
    }

    get rangeLength() {
        return this.maxScale - this.minScale;
    }
}
