
import { Component, Mixins } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RankingChainHeader from '@/modules/cluster/components/ranking/ranking-chain-header.vue';
import RankingChainList from '@/modules/cluster/components/ranking/ranking-chain-list.vue';
import RankingClusterOtelMixin from '@/modules/open-telemetry/mixins/ranking/ranking-cluster-otel.mixin';
import RankingClusterActions from '../components/ranking/ranking-cluster-actions.vue';
import ClusterPageMixin from '../mixins/cluster-page.mixin';

@Component({
    components: {
        PageWrapper,
        RankingChainList,
        RankingChainHeader,
        RankingClusterActions,
    },
})
export default class RankingClusterPage extends Mixins(RankingClusterOtelMixin, ClusterPageMixin) {}
