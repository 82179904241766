
import { Component, Vue, Prop } from 'vue-property-decorator';

/**
 * Component to show pagination.
 * @param value current's page number.
 * @param pages total number of pages.
 * @param maxPagesShown maximum number of pages to show, others will be `...` (`...` is count as page).
 * @param hideLimitPages should first and last page be hidden if `maxPagesShown` less then `pages`
 * @emits input action on clicking any button, payload is page's number.
 */
@Component
export default class Pagination extends Vue {
    @Prop({ type: Number, required: true })
    value!: number;

    @Prop({ type: Number, required: true })
    pages!: number;

    @Prop({ type: Number, default: 10 })
    maxPagesShown!: number;

    @Prop({ type: Boolean, default: false })
    disabled!: boolean;

    activePage = this.value;

    updated() {
        if (this.activePage !== this.value) {
            this.activePage = this.value;
        }
    }

    get pagesOffset() {
        if (this.pages < this.maxPagesShown) {
            return 0;
        }

        let offset = this.activePage - Math.ceil(this.maxPagesShown / 2);

        if (offset < 0) {
            return 0;
        }

        if (offset + this.maxPagesShown > this.pages) {
            offset = this.pages - this.maxPagesShown;
        }

        return offset + 1;
    }

    get shownPages() {
        if (this.pages < this.maxPagesShown) {
            return this.pages;
        }

        return this.maxPagesShown;
    }

    get isLimitBtnShown() {
        return this.pages > this.maxPagesShown;
    }

    get isLeftLimit() {
        return !!this.pagesOffset;
    }

    get isRightLimit() {
        return !!((this.pagesOffset + this.maxPagesShown) < this.pages);
    }

    handleChange(page: number) {
        if (page < 1 || page > this.pages || page === this.activePage) {
            return;
        }

        this.$emit('input', page);
        this.activePage = page;
    }
}
