
import { Component, Prop, Vue } from 'vue-property-decorator';
import Stars from '@/modules/common/components/ui-kit/stars.vue';

@Component({
    components: {
        Stars,
    },
})
export default class ScoreRating extends Vue {
    @Prop({
        type: Number,
    })
    private rating!: number;

    @Prop({
        type: Number,
    })
    private scale!: number;

    @Prop({
        type: Number,
    })
    private growth?: -1 | 0 | 1;

    @Prop({
        type: Boolean,
    })
    private skeleton!: boolean;

    created(): void {
    }

    get amountStars() {
        return Math.round(this.rating / this.scale / 2 * 10);
    }
}
